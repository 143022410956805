import { IMembership, Program } from '@guider-global/shared-types';
import { Paper, Divider, Typography, Skeleton } from '@mui/material';
import { FC } from 'react';
import {
  Control,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  FieldValues,
} from 'react-hook-form';

import { IMembershipForm } from 'store/slices/formsSlice';

import { FlatPageHeader } from 'components/Page/FlatPageHeader';
import ProgramManageGuideForm from 'forms/ProgramManageGuideForm';
import { ProgramManageGuidePageRole } from '../ProgramManageGuidePage';

export interface ProgramManageGuideMobilePageProps {
  title: string | undefined;
  pageSubtitle: string;
  programName: string;
  profileTypeHeading: string;

  control: Control;
  errors: FieldErrors;
  onSubmit: SubmitHandler<IMembershipForm>;
  program?: Program;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  guideMembership?: IMembership;
  role: ProgramManageGuidePageRole;
  profilePublishHeading: string | undefined;
  profilePublishDescription: string | undefined;
  discardChangesButtonLabel: string | undefined;
  updateProfileButtonLabel: string | undefined;
  profilePendingHeading: string;
  profilePendingDescription: string;
  goalCategories: { label?: string | undefined; value: string }[];
  loading: boolean;
  isValid: boolean;
  isDirty: boolean;
}

const ProgramManageGuideMobilePage: FC<ProgramManageGuideMobilePageProps> = ({
  title,
  pageSubtitle,
  programName,
  profileTypeHeading,
  control,
  errors,
  onSubmit,
  program,
  handleSubmit,
  loading,
  guideMembership,
  profilePublishHeading,
  profilePublishDescription,
  discardChangesButtonLabel,
  updateProfileButtonLabel,
  profilePendingHeading,
  profilePendingDescription,
  isValid,
  isDirty,
  role,
  goalCategories,
}) => {
  return (
    <>
      <FlatPageHeader header={title} subheader={pageSubtitle} />
      <Paper sx={{ px: 3 }}>
        <Divider sx={{ mb: 3 }} />
        <Typography variant="h5">{programName}</Typography>
        <Typography variant="body1" sx={{ mb: 3 }} color="text.secondary">
          {profileTypeHeading}
        </Typography>
        {guideMembership ? (
          <ProgramManageGuideForm
            goalCategories={goalCategories}
            control={control}
            errors={errors}
            onSubmit={onSubmit}
            program={program}
            handleSubmit={handleSubmit}
            loading={loading}
            guideMembership={guideMembership}
            isValid={isValid}
            isDirty={isDirty}
            role={role}
            profilePublishHeading={profilePublishHeading}
            profilePublishDescription={profilePublishDescription}
            discardChangesButtonLabel={discardChangesButtonLabel}
            updateProfileButtonLabel={updateProfileButtonLabel}
            profilePendingHeading={profilePendingHeading}
            profilePendingDescription={profilePendingDescription}
          />
        ) : (
          <>
            <Skeleton sx={{ height: '98px', transform: 'none' }} />
            <Skeleton sx={{ height: '98px', mt: 2, transform: 'none' }} />
            <Skeleton sx={{ height: '98px', mt: 2, transform: 'none' }} />
          </>
        )}
      </Paper>
    </>
  );
};

export default ProgramManageGuideMobilePage;
