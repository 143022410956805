// external
import { useAuth } from '@guider-global/auth-hooks';
import { ThemeProvider, useTheme } from '@mui/material';

// hooks
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  useOrganization,
  useBaseLanguage,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';

import { useLocalization, useMixpanelEvents, useTrackEvent } from 'hooks';
import { ProgramListContainer } from 'containers/Programs';
import { deepMerge } from 'utils';
import { AlertBox, ButtonStackItem, theme } from '@guider-global/ui';
import { useState } from 'react';
import { FilterPageView } from 'views';
import { EProgramVariation, ICustomField } from '@guider-global/shared-types';
import { useMemberships, useProfiles } from '@guider-global/front-end-hooks';

export const ProgramsListPage = () => {
  const [value, setValue] = useState('all');

  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);

  // Styles

  const organizationTheme = useTheme();
  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );

  // Base Language
  const { baseLanguage } = useBaseLanguage({ localeCode });

  // Derivations

  const {
    title: programsListTitle,
    subtitle: programsListSubtitle,
    all_programs_label,
    cohort_programs_label,
    group_programs_label,
    empty_state,
  } = baseLanguage?.programs?.programs_list ?? {};

  // Profile
  const { profiles } = useProfiles({});
  const profile = profiles?.at(0);
  const profileOrganizationFields =
    profile?.organizationFields as ICustomField[];

  // Org

  const { organization } = useOrganization({ organizationSlug });
  const openMatchingEnabled =
    organization?.open_matching.enable_open_matching ?? false;

  // Auth
  const auth = useAuth({ waitForAuthentication: true });

  // Memberships
  const { memberships } = useMemberships({});

  // Programs

  const { getAuthorizedPrograms } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  const programs = getAuthorizedPrograms(
    profileOrganizationFields,
    memberships,
  );

  const availableProgramsToFilter = [
    'all',
    openMatchingEnabled ? 'open' : undefined,
    ...new Set(
      programs.map((program) => program.program_details?.program_variation),
    ),
  ].filter(Boolean);

  useTrackEvent({
    track: {
      eventKey: 'program-list-visited',
      eventParams: {
        organization: { organizationSlug },
        programList: { organizationSlug },
      },
    },
    for: organizationSlug,
    if: auth.isAuthorized,
  });

  useMixpanelEvents({ navigationEventName: 'Programs' });

  function getFilterButtonText(variation?: string) {
    switch (variation) {
      case 'all': {
        return all_programs_label;
      }
      case 'open': {
        return 'On-going';
      }
      case EProgramVariation.Individual: {
        return cohort_programs_label;
      }
      case EProgramVariation.Group: {
        return group_programs_label;
      }
      default:
        break;
    }
  }

  const hasMultipleProgramVariations = availableProgramsToFilter.length > 2;

  return (
    <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
      <FilterPageView
        title={programsListTitle}
        subtitles={[{ text: programsListSubtitle, fontSize: '14px' }]}
        displayButtons={hasMultipleProgramVariations}
        buttons={[
          ...availableProgramsToFilter.map((variation, index) => {
            return {
              key: `${index}-filter-button`,
              variant: value === variation ? 'contained' : 'outlined',
              label: getFilterButtonText(variation),
              color: 'info',
              onClick: () => setValue(variation ?? 'all'),
              sx: {
                borderRadius: '20px',
                width: 'fit-content',
                px: { xs: 3, md: 5 },
                mb: { xs: 2, md: 0 },
              },
            } as ButtonStackItem;
          }),
        ]}
      >
        {availableProgramsToFilter.length === 0 ? (
          <AlertBox
            sx={{ p: 5 }}
            title={empty_state?.title}
            description={empty_state?.description}
            variant="iconWithBackground"
            iconVariant="warning"
          />
        ) : (
          <ProgramListContainer
            value={value}
            hasMultipleProgramVariations={hasMultipleProgramVariations}
          />
        )}
      </FilterPageView>
    </ThemeProvider>
  );
};
